import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["pages", "tabs", "previous", "next", "label", "reminder"]

  connect() {
    this.tabsTarget.classList.add('active')
    this.firstConsultationNextBtn()
  }

  fetchForm(event) {
    const url = event.target.dataset.url
    const ifFetch = event.target.dataset.notfetch ? false : true
    // only swap in a new form on a click event if url is defined

    if (url && ifFetch) {
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          // load the new form in the placeholder
          this.pagesTarget.innerHTML = data.page
        }
      })
      this.updateCSSActive(event)
      this.updatePreviousNext(event)
    }
  }

  updateCSSActive(event) {
    this.tabsTargets.forEach((element) => {
      element.classList.remove('active')

      if (element.dataset.url === event.target.dataset.url) {
        element.classList.add('active')
      }
    })
  }

  updatePreviousNext(event) {
    const urlArray = this.tabsTargets.map((_element, index, arr) => { return arr[index].dataset.url })
    const targetIdx = urlArray.findIndex(function (element) { return element === event.target.dataset.url })

    // This will be the number of tabs + 2 (the previous next buttons)
    // (currently not including history)
    const tabLength = urlArray.length

    this.previousTarget.hidden = false;
    this.nextTarget.innerText = this.nextTarget.dataset.btntext
    this.nextTarget.classList.remove("disabled")
    this.nextTarget.hidden = false
    this.labelTarget.hidden = true

    // confirm and action are used on the last tab for completing the consultation
    // default to blank and set values only in case 3 (last tab)
    this.nextTarget.dataset.action = ''

    switch (tabLength - targetIdx) {
      case tabLength:
        // this is the first tab
        this.previousTarget.hidden = true;
        this.nextTarget.dataset.url = urlArray[targetIdx + 1]
        this.showHideReminder('show')
        this.firstConsultationNextBtn()
        break;
      case 3:
        // this is the last tab (total tabs - previousnext - index)
        this.previousTarget.dataset.url = urlArray[targetIdx - 1]
        this.nextTarget.innerText = this.nextTarget.dataset.btntextcomp
        this.showHideReminder('hide')
        this.disableNextBtn()
        break;
      default:
        this.previousTarget.dataset.url = urlArray[targetIdx - 1]
        this.nextTarget.dataset.url = urlArray[targetIdx + 1]
        this.showHideReminder('show')
    }
  }

  firstConsultationNextBtn() {
    const { isFirstConsultation, firstConsultationBtnnext } = this.nextTarget.dataset

    if (isFirstConsultation) {
      this.nextTarget.innerText = firstConsultationBtnnext
    }
  }

  disableNextBtn() {
    if (this.nextTarget.dataset.disableChecker === "true") {
      this.nextTarget.hidden = true
      this.labelTarget.hidden = false
    } else {
      this.nextTarget.dataset.action = "click->save-complete#saveAndComplete"
    }
  }

  showHideReminder(type) {
    if (this.hasReminderTarget) {
      if(type == 'show') {
        this.reminderTarget.hidden = true
      }

      if(type == 'hide') {
        this.reminderTarget.hidden = false
      }
    }
  }
}
