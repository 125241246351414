import { Controller } from 'stimulus';
import autocomplete from "autocomplete.js";
import axios from 'axios';

export default class extends Controller {
	static targets = ["uid", "firstName", "lastName", "fullName"];

	source(fieldName) {
		const url = this.data.get("url");
		return(query, callback) => {
			axios.get(url + `?${fieldName}=${query}`)
				.then((response) => {
      		callback(response.data);
    		});
		}
  }

  connect() {
		if (this.hasUidTarget) {
			this.acUid = autocomplete(this.uidTarget, { hint: false }, [
				{
					source: this.source('uid'),
					debounce: 200,
					templates: {
						suggestion: function(suggestion) {
							return suggestion.uid;
						},
					},
				},
			]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
				this.acUid.autocomplete.setVal(suggestion.uid);
			});
		}

		if(this.hasFirstNameTarget) {
			this.acfName = autocomplete(this.firstNameTarget, { hint: false }, [
				{
					source: this.source('first_name'),
					debounce: 200,
					templates: {
						suggestion: function(suggestion) {
							return suggestion.first_name;
						},
					},
				},
			]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
				this.acfName.autocomplete.setVal(suggestion.first_name);
			});
		}

		if(this.hasLastNameTarget) {
			this.aclName = autocomplete(this.lastNameTarget, { hint: false }, [
				{
					source: this.source('last_name'),
					debounce: 200,
					templates: {
						suggestion: function(suggestion) {
							return suggestion.last_name;
						},
					},
				},
			]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
				this.aclName.autocomplete.setVal(suggestion.last_name);
			});
		}

		if (this.hasFullNameTarget) {
			this.acfullName = autocomplete(this.fullNameTarget, { hint: false }, [
				{
					source: this.source('fullname'),
					debounce: 200,
					templates: {
						suggestion: function(suggestion) {
							return suggestion.fullname;
						},
					},
				},
			]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
				this.acfullName.autocomplete.setVal(suggestion.fullname);
			});
		}
  }

	checkButton(event) {
		if (!this.uidTarget.value && !this.firstNameTarget.value && !this.lastNameTarget.value) {
			event.preventDefault()
		}
	}
}
