import Flatpickr from 'stimulus-flatpickr'
import Rails from '@rails/ujs';

export default class extends Flatpickr {
  initialize() {
    const isDisabled = this.element.dataset.disableChecker;

    this.config = {
      allowInput: true,
      altFormat: 'd-m-Y',
      dateFormat: 'd-m-Y',
      disableMobile: 'true',
      parseDate: (datestr) => {
        const splitDate = datestr.split("-")
        const dateFormat = splitDate[0].length == 4 ? 'YYYY-MM-DD' : 'DD-MM-YYYY'
        return moment(datestr, dateFormat).toDate();
      },
      formatDate: (date) => {
        return moment(date).format('DD-MM-YYYY');
      }
    }

    if (isDisabled == "true") {
      this.element.disabled = 'disabled'
    }
  }

  change(selectedDates, dateStr, instance, event) {
    if (!("requireSubmit" in this.element.dataset) && selectedDates.length > 0) {
      const form = this.element.closest('form');

      if ("requestAskConfirm" in this.element.dataset && this.element.dataset.requestAskConfirm != '') {
        this.confirmAndSend(selectedDates[0])
      } else if (form) {
        const { requestUrl } = this.element.dataset
        Rails.ajax({
          type: 'PUT',
          url: requestUrl,
          data: new FormData(form)
        })
      } else {
        this.sendData(selectedDates[0])
      }
    }
  }

  confirmAndSend(selectedDate) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary confirm-button',
        cancelButton: 'btn btn-primary cancel-button',
        container: 'dialog-container',
        popup: 'dialog-box'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      text: this.element.dataset.confirmMessage,
      showCancelButton: true,
      confirmButtonText: 'OK',
      reverseButtons: true,
      target: '#content-container'
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendData(selectedDate)
      }
    })
  }

  sendData(selectedDate, event) {
    Rails.ajax({
      type: this.element.dataset.requestType,
      url: this.element.dataset.requestUrl,
      data: `${this.element.dataset.requestInfo}=${selectedDate}`
    })
  }
}
