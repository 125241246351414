import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['addPartnerLabel', 'addChildLabel', 'removePartnerLabel', 'removeChildLabel', 'select']

  connect() {
    const selectTargetValue = this.selectTarget.value
    const partnerLinkBody = this.addPartnerLabelTarget.closest('tbody')
    const childLinkBody = this.addChildLabelTarget.closest('tbody')

    if(selectTargetValue != '' && selectTargetValue != 'Single'){
      partnerLinkBody.removeAttribute('hidden') && partnerLinkBody.getAttribute('hidden')
      childLinkBody.removeAttribute('hidden') && childLinkBody.getAttribute('hidden')
    }
  }

  insertForm(event) {
    const parentNode = event.target.closest('tbody')
    const infoType = event.target.dataset.infoType
    const infoCount = event.target.dataset.infoCount
    
    Rails.ajax({
      type: 'GET',
      url: event.target.dataset.url,
      data: new URLSearchParams({ info_type: infoType, info_count: infoCount }).toString(),
      success: (data) => {
        parentNode.insertAdjacentHTML('beforeBegin', data.entry)
        event.target.dataset.infoCount = parseInt(infoCount) + 1
      }
    })
  }

  removeForm(event) {
    const fieldGroup = event.target.closest('tbody')
    const infoType = event.target.dataset.infoType

    fieldGroup.remove()

    if(infoType == 'partner') {
      const addInfoCount = this.addPartnerLabelTarget.dataset.infoCount
      this.addPartnerLabelTarget.dataset.infoCount = parseInt(addInfoCount) - 1
    }

    if(infoType == 'child') {
      const addInfoCount = this.addChildLabelTarget.dataset.infoCount
      this.addChildLabelTarget.dataset.infoCount = parseInt(addInfoCount) - 1
    }
    
  }

  showHideLink(event) {
    const targetValue = event.target.value
    const partnerLinkBody = this.addPartnerLabelTarget.closest('tbody')
    const childLinkBody = this.addChildLabelTarget.closest('tbody')
    
    if(targetValue == 'Single') {
      partnerLinkBody.setAttribute('hidden', 'hidden')
      childLinkBody.setAttribute('hidden', 'hidden')
      this.removePartnerLabelTarget.click()
      this.removeChildLabelTarget.click()
    } else {
      if(partnerLinkBody.getAttribute('hidden')) {
        partnerLinkBody.removeAttribute('hidden')
        this.addPartnerLabelTarget.click()
      }

      if(childLinkBody.getAttribute('hidden')) {
        childLinkBody.removeAttribute('hidden')
        this.addChildLabelTarget.click()
      }
    }
  }
}