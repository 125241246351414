import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['reminder']

  connect() {
    if (this.hasReminderTarget) {
      this.reminderTarget.hidden = true
    }

    toastr.options = { positionClass: "toastr-top-center" };
  }

  sendReminder() {
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: this.reminderTarget.dataset.url,
      success: ({ success, message }) => { 
        success ? toastr.success(message) : toastr.error(message)  
      }
    })
  }
}