import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = [ "item", "note"]

	changeTuberculosisTreament() {
		this.saveFormElement();
		const isChecked = document.getElementsByName('consultation_page[has_tuberculosis_treatment]')[0].checked
		document.getElementById('tuberculosisTreament').hidden = !isChecked
	}

	saveFormElement() {
		const url = this.element.dataset.url
		Rails.ajax({
			type: 'PUT',
			url: url,
			data: new FormData(this.element)
		})
	}
}
